
import {computed, defineComponent, reactive, ref, watch, watchEffect} from 'vue';
import useVuelidate from '@vuelidate/core';
import {helpers, maxValue, minValue, required} from '@vuelidate/validators';
import {DateTime} from 'luxon';

import Checkbox from 'primevue/checkbox';
import GvShops from '@/views/components/GvShops.vue';
import GvInputNumber from '@/views/components/GvInputNumber.vue';
import GvCalendar from '@/views/components/GvCalendar.vue';
import GvVehicles from '@/views/components/GvVehicles.vue';
import NewGvCalendar from "@/views/components/NewGvCalendar.vue";

import {AppointmentInput, Shop, Vehicle} from '@/types';
import {formatISO} from "date-fns";
import GvCalendarMultipleSelection from "@/views/components/GvCalendarMultipleSelection.vue";
import GvInputTime from "@/views/components/GvInputTime.vue";
import {sortDates} from "@/utils";

export default defineComponent({
  components: {
    GvInputTime,
    NewGvCalendar, GvShops, GvInputNumber, GvCalendar, GvVehicles, Checkbox, GvCalendarMultipleSelection
  },
  props: {
    index: {type: Number, required: true},
    startFromRoute: String,
    endFromRoute: String
  },
  emits: ['result'],
  setup(props, {emit}) {
    const repetition = ref(false);
    const minDateObj = DateTime.now().plus({hours: 30});
    const minDateEndObj = ref(minDateObj.plus({hours: [5, 6, 7].includes(minDateObj.weekday) ? 3 : 2}));

    const minDate = ref(minDateObj.toJSDate());
    const maxDate = ref(minDateEndObj.value.plus({weeks: 4}).toJSDate());
    const start = ref(props.startFromRoute ? DateTime.fromISO(props.startFromRoute) : minDateObj);
    const end = ref(props.startFromRoute ? DateTime.fromISO(props.startFromRoute) : minDateEndObj);
    // if start hour is < 12 then fix the hour to 12
    if (start.value.hour < 12) {
      start.value = start.value.set({hour: 12, minute: 0, second: 0, millisecond: 0});
    }
    // if start hour is > 12 but < 19 then fix the hour to 19
    if (start.value.hour < 19) {
      start.value = start.value.set({hour: 19, minute: 0, second: 0, millisecond: 0});
    }

    end.value = start.value.plus({hours: [5, 6, 7].includes(start.value.weekday) ? 3 : 2});

    const state = reactive({
      hoursDuration: 2,
      minutesDuration: 0,
      shop: '',
      riders: 1,
      dates: [],
      startTime: "",
      endTime: end.value.toJSDate(),
      vehicle: '',
      repetitionDays: []
    });

    const isStartTimeAllowed = (): boolean => {
      const startTime = state.startTime
      const firstDay = sortDates(state.dates)[0]
      //  get current date
      const todayDateTime = firstDay.set({
        hour: Number(startTime.split(":")[0]),
        minute: Number(startTime.split(":")[1]),
      })

      const now = DateTime.now()

      //is the first dates today?
      const diff = todayDateTime.diff(now, "hours").hours
      return diff > 30
    }


    const AreDaysAllWeekend = () => {
      const allAreWeekend = state.dates.some((date) => {
        return [5, 6, 7].includes(DateTime.fromJSDate(date).weekday)
      })

      return allAreWeekend ? minValue(2) : minValue(2)
    }

    const rules = computed(() => ({
      shop: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      riders: {
        required: helpers.withMessage('Questo campo è obbligatorio', required),
        minValue: helpers.withMessage('Il valore minimo è 1', minValue(1))
      },
      startTime: {
        required: helpers.withMessage('Questo campo è obbligatorio', required),
        minDateTime: helpers.withMessage("L'appuntamento deve iniziare minimo tra 30 ore", isStartTimeAllowed)
      },
      dates: {
        minLength: helpers.withMessage('Seleziona almeno una data', (value: Date[]) => value.length > 0)
      },
      vehicle: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      repetitionDays: {},
      hoursDuration: {
        min: helpers.withMessage('Il valore minimo è 2', AreDaysAllWeekend()),
      },
      minutesDuration: {}
    }));
    const v$ = useVuelidate(rules, state);

    const onStartCalendarUpdate = (e: any) => {
      v$.value.startTime.$model = e
    }

    // watch(
    //     () => [v$.value.startTime],
    //     ([startTime]) => {
    //       if (!startTime.$invalid && startTime.$dirty) {
    //         const newStart = DateTime.fromJSDate(startTime.$model as Date);
    //         start.value = newStart;
    //         const newMinDateEnd = newStart.plus({hours: [5, 6, 7].includes(newStart.weekday) ? 3 : 2});
    //         minDateEndObj.value = newMinDateEnd;
    //         maxDate.value = newMinDateEnd.plus({weeks: 4}).toJSDate();
    //         startTime.$reset();
    //       }
    //     }
    // );

    watchEffect(() => {
      if (!repetition.value) {
        state.repetitionDays = [];
      }
    });

    watch(
        () => v$.value,
        newValue => {
          const newData: AppointmentInput = {
            shop: newValue.shop.$model as { shopName: string; shop: Shop; },
            riders: newValue.riders.$model as number,
            minutesDuration: newValue.minutesDuration.$model as number,
            hoursDuration: newValue.hoursDuration.$model as number,
            dates: newValue.dates.$model as Date[],
            vehicle: newValue.vehicle.$model as Vehicle,
            repetitionDays: newValue.repetitionDays.$model as string[],
            startTime: newValue.startTime.$model as string
          };

          emit('result', {
            index: props.index,
            valid: !v$.value.$invalid,
            data: newData
          });
        },
        {deep: true}
    );

    return {
      minDate,
      minDateEndObj,
      maxDate,
      v$,
      repetition,
      onStartCalendarUpdate,
    };
  }
});
