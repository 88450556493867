
import {onBeforeMount, ref} from 'vue';

import {useStore} from 'vuex';
import {key} from '@/store';
import {useRouter} from 'vue-router';
import {signupErrors} from '@/views/errors';
import Toast from 'primevue/toast';
import {useToast} from 'primevue/usetoast';
import GvSpinner from '@/views/components/GvSpinner.vue';
import UserProfileInfoManagement from '@/views/components/UserProfileInfoManagement.vue';
import {Axios} from "@/Axios";
import {performLogout} from "@/utils";
import {UserInfo} from "@/types";
import {auth} from "@/firebase";
import router from "@/router";

export default {
  components: {
    UserProfileInfoManagement,
    GvSpinner,
    Toast
  },
  setup() {

    const toast = useToast();
    const isLoading = ref(false);
    const store = useStore(key);
    const disableFields = ref(false);
    const canSignup = ref(false);
    const goToHomePage = () => {
      auth.signOut()
          .then(() => router.push("/signin"))
          .catch(() => {
        toast.add({severity: 'error', summary: 'Errore', detail: 'Effettua il logut manualmente', life: 3000});
      })
    };
    const performSignup = () => {
      isLoading.value = true;
      disableFields.value = true;
      Axios
          .post(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/signup`, sanitizeSignupData(store.state.signupForm.userInfo))
          .then(() => {
            toast.add({
              severity: 'success',
              summary: 'Fatto!',
              detail: "Segui le istruzioni ricevute per email per completare la registrazione",
              life: 5000
            });
            canSignup.value = false;
            setTimeout(goToHomePage, 4000);
          })
          .catch(err => {
            disableFields.value = false;
            console.error(err);
            toast.add({
              severity: 'error',
              summary: 'Registrazione fallita',
              detail: signupErrors[err.data.errorInfo?.code] ? signupErrors[err.data.errorInfo.code] : 'Qualcosa è andato storto',
              life: 5000
            });
          })
          .finally(() => {
            isLoading.value = false;
          });

    };

    const sanitizeSignupData = (signupData: UserInfo) => {
      const dataToUpdate = signupData
      delete dataToUpdate.phoneNumber;
      return dataToUpdate
    }

    const updateCanSignup = (value: boolean) => {
      canSignup.value = value;
    };

    return {
      performSignup,
      isLoading,
      updateCanSignup,
      canSignup,
      disableFields
    };
  }
};
