
import UserInfoEdit from '@/views/Profile/UserInfoEdit.vue';
import LegalEntityEdit from '@/views/Profile/LegalEntityEdit.vue';
import ShopsEdit from '@/views/Profile/shopsEdit.vue';


import {useRouter} from 'vue-router';
import {defineComponent, ref} from "vue";
import {auth} from "@/firebase";
import {userRegistrationComplete} from "@/utils";

export default defineComponent({
  components: {
    ShopsEdit,
    LegalEntityEdit,
    UserInfoEdit
  },
  setup() {
    const router = useRouter();
    const isUserRegistrationComplete = ref(false);
    const back = () => {
      router.back();
    };

    auth.onAuthStateChanged( async (user) => {
      if (user) {
        isUserRegistrationComplete.value = await userRegistrationComplete(user);
      }
    });


    return {
      back,
      isUserRegistrationComplete
    };
  }
});
