
	import { auth } from "@/firebase";
	import { ref } from "vue";
	import { useRouter } from "vue-router";
	import { useStore } from "vuex";
	import { key } from "@/store";
	import { performLogout, userRegistrationComplete } from "@/utils";

	export default {
		name: "Header",
		setup() {
			const store = useStore(key);
			const router = useRouter();
			const initials = ref<string | null>(null);
			const showDropDown = ref(false);
			const showUserMenu = ref(false);
			const showProfileOption = ref(false);

			const goToProfile = () => {
				router.push("/profile");
			};
			const goToHomePage = () => {
				//get custom claims from firebase
				auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
					if (idTokenResult.claims.legalEntity) {
						router.push("/csac");
					} else {
						store.commit("reset");
						performLogout()
							.then(() => {
								router.push("/");
							})
							.catch((error) => {
								console.log(error);
							});
					}
				});
			};

			const changeDropDownVisibility = (state: boolean) => {
				showDropDown.value = state;
			};

			const signOutUser = () => {
				performLogout()
					.then(() => {
						store.commit("reset");
						changeDropDownVisibility(false);
					})
					.catch((error) => {
						console.log(error);
					});
			};

			auth.onIdTokenChanged(async (user) => {
				if (user) {
					const rgx = user.displayName?.match(/\b(\w)/g);
					if (rgx) initials.value = rgx.join("");
					else initials.value = null;
					showUserMenu.value = true;
					showProfileOption.value = await userRegistrationComplete(user);
				} else {
					showDropDown.value = false;
					showUserMenu.value = false;
					initials.value = null;
				}
			});
			return {
				showProfileOption,
				goToProfile,
				signOutUser,
				goToHomePage,
				showUserMenu,
				changeDropDownVisibility,
				showDropDown,
				initials,
			};
		},
	};
