
import {computed, defineComponent, onMounted, reactive, watch} from 'vue';
import {helpers, minLength, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import GvInputText from '@/views/components/GvInputText.vue';
import {useStore} from 'vuex';
import {key} from '@/store';
import GVAutocomplete from "@/views/components/GvAddressAutocomplete.vue";

export default defineComponent({
  components: {GvInputText, GVAutocomplete},
  props: {
    shopId: {type: String, required: true},
    data: {type: Object, required: false},
    disableFields: {type: Boolean, default: false},
    showAvatar: {type: Boolean, default: false}
  },
  emits: ['isValid'],
  setup(props, {emit}) {
    onMounted(() => {
      if (props.data !== null) {
        firstStepFields.referrer = props.data?.referrer;
        firstStepFields.signName = props.data?.signName;
        firstStepFields.phoneNumber = props.data?.phoneNumber;
        firstStepFields.address = props.data?.address;
      }
    });
    const store = useStore(key);
    const firstStepFields = reactive({
      signName: '',
      referrer: '',
      phoneNumber: '',
      address: ''
    });

    const rules = computed(() => ({
      signName: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      phoneNumber: {
        minLength: helpers.withMessage("Deve avere almeno un carattere", minLength(0))

      },
      address: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      referrer: {
        minLength: helpers.withMessage("Deve avere almeno un carattere", minLength(0))

      }
    }));

    const v$ = useVuelidate(rules, firstStepFields);

    watch(
        () => v$.value,
        newValue => {
          store.dispatch('updateSingleShop', {
            shopData: {
              shopId: props.shopId,
              signName: newValue.signName.$model,
              referrer: firstStepFields.referrer,
              phoneNumber: newValue.phoneNumber.$model,
              address: newValue.address.$model
            },
            isInvalid: newValue.$invalid
          });
        }
    );

    const canSubmit = computed((): boolean => v$.value.$invalid);
    watch(canSubmit, (isValid: boolean) => emit('isValid', isValid));

    return {v$, firstStepFields, props};
  }
});
