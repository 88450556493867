
import {defineComponent} from 'vue';
import Calendar from 'primevue/calendar';

export default defineComponent({
  components: {Calendar},
  props: {
    dateFormat: {type: String, default: 'dd/mm/yy'},
    showIcon: {type: Boolean, default: true},
    minDate: {type: Date},
    maxDate: {type: Date},
    fieldName: {type: String, required: true},
    errors: {type: Array, required: true},
    placeholder: {type: String, default: ''},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    modelValue: {
      type: Date
    }
  },
  emits: ['update:modelValue'],
  methods: {
    emitDate(value: Date) {
      this.$emit('update:modelValue', value);
    }
  }
});
