
import FullCalendar, {CalendarOptions, DateSelectArg, EventClickArg, EventContentArg} from '@fullcalendar/vue3';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import Card from 'primevue/card';

import {computed, defineComponent} from 'vue';
import {mapGetters, mapState, useStore} from 'vuex';
import {key, State} from '@/store';
import {useRouter} from 'vue-router';
import {DateTime} from 'luxon';
import {useToast} from "primevue/usetoast";
import {getAppointment} from "@/service/Appoinment";

export default defineComponent({
  components: {FullCalendar, Card},
  setup() {
    const store = useStore(key);
    const router = useRouter();
    const toast = useToast();
    let prevDates = {start: '', end: ''}
    const handleDateSelect = (selectInfo: DateSelectArg) => {
      router.push({
        path: '/csac/add',
        query: {
          start: selectInfo.startStr,
          end: DateTime.fromJSDate(selectInfo.end)
              .minus({days: 1})
              .toISODate()
        }
      });
    };

    const handleEventClick = (clickInfo: EventClickArg) => {
      if (clickInfo.event.start) {
        const startStr = DateTime.fromJSDate(clickInfo.event.start).toISODate();
        router.push({path: '/csac/add', query: {start: startStr}});
      }
    };

    const eventContentRenderer = (args: EventContentArg) => {
      return {
        html: `
                    <div class="custom-event p-shadow-1 p-m-1 p-px-3 p-py-2"">
                        <b>${args.event.title}</b>
                        <div class="p-mt-2 p-d-flex">
                            <span style="flex: 1">${args.event.extendedProps.riders} rider</span>
                            <span>
                                ${DateTime.fromISO(args.event.extendedProps.startTime).toFormat("HH:mm")}
                                -
                                ${DateTime.fromISO(args.event.extendedProps.endTime).toFormat("HH:mm")}
                            </span>
                        </div>
                    </div>
            `
      };
    };

    const handleDateRangeChange = (info: { start: Date, end: Date, startStr: string, endStr: string }) => {
      if (prevDates.start !== info.startStr || prevDates.end !== info.endStr) {
        prevDates.start = info.startStr;
        prevDates.end = info.endStr
        getAppointment(store.getters.getShopList, info.start.getTime(), info.end.getTime()).catch(err => {
          toast.add({
            severity: 'error',
            summary: err,
            detail: '',
            life: 5000
          });
        })

      }
    }

    return {
      list: computed(() => store.getters.getAppointments),
      handleDateSelect,
      handleEventClick,
      eventContentRenderer,
      handleDateRangeChange,
    };
  },
  computed: {
    ...mapState({
      events: state =>
          (state as State).appointments.list
              .map(l => l.appointmentData)
              .map(l => ({
                id: l.id,
                title: l.shop.name,
                date: l.startTime,
                extendedProps: {riders: l.riders, startTime: l.startTime, endTime: l.endTime}
              }))
    }),
    ...mapGetters('appointments', ['list'])
  },

  data() {
    return {
      calendarOptions: {
        datesSet: this.handleDateRangeChange,
        aspectRatio: 3,
        locale: itLocale,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        selectable: true,
        select: this.handleDateSelect,
        dayMaxEventRows: true,
        eventClick: this.handleEventClick,
        eventContent: this.eventContentRenderer
      } as CalendarOptions
    };
  }
});
