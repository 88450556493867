
import GvInputText from '@/views/components/GvInputText.vue';
import {computed, reactive, ref} from 'vue';
import {email, helpers, minLength, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {useRouter} from 'vue-router';
import {auth} from '@/firebase';
import {signinErrors} from '@/views/Signin/errors';
import {useToast} from 'primevue/usetoast';
import Toast from 'primevue/toast';
import GvSpinner from '@/views/components/GvSpinner.vue';
import {passwordRegex} from '@/utils';
import {useStore} from 'vuex';
import {key} from '@/store';
import {signInWithEmailAndPassword} from 'firebase/auth';
import GVPassword from "@/views/components/GVPassword.vue";


export default {
  components: {GVPassword, GvSpinner, GvInputText, Toast},
  setup() {
    const store = useStore(key);
    const router = useRouter();
    const state = reactive({
      email: '',
      password: ''
    });

    const rules = computed(() => ({
      email: {
        email: helpers.withMessage("L'indirizzo email non é valido", email),
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      password: {
        minLength: helpers.withMessage('Deve contenere almeno 8 caratteri', minLength(8)),
        mustBeValid: helpers.withMessage('Deve contenere almeno un carattere maiuscolo, 1 minuscolo e uno speciale', helpers.regex(passwordRegex)),
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      }
    }));
    const toast = useToast();
    const isLoading = ref(false);
    const v$ = useVuelidate(rules, state);
    const goToResetPassword = () => {
      router.push('/forgot-password');
    };

    const showToast = (severity: string, summary: string, detail: string, life?: 5000) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life
      });
    }

    const performLogin = () => {
      isLoading.value = true;
      signInWithEmailAndPassword(auth, state.email, state.password)
          .then(result => {
            const isEmailVerified = result.user?.emailVerified
            isEmailVerified ?
                auth.currentUser
                    ?.getIdTokenResult(false)
                    .then(token => {
                      store.commit('updateFullNameUser', token.claims.name);
                      token.claims.legalEntity ? router.push('/csac') : router.push('/signupCompletion/company');
                    })
                    .catch(() => showToast('error', 'Errore login', signinErrors['auth/unknown']))
                : showToast('warn', 'Errore login', signinErrors["auth/email-not-verified"])

            console.log('result', result.user?.getIdToken(false));
          })
          .catch(err => {
                console.log(err);
                showToast('warn', 'Errore login', signinErrors[err.code])
              }
          )
          .finally(() => (isLoading.value = false));
    };

    return {v$, goToResetPassword, performLogin, isLoading};
  }
};
