
import {onMounted, reactive} from 'vue';
import {useRouter} from 'vue-router';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import EmailVerification from '@/views/Auth/EmailVerification.vue';
import {queryToObj} from "@/utils";

export default {
  components: {EmailVerification, ResetPassword},
  setup() {
    const router = useRouter();
    const state = reactive({
      opType: '',
      actionCode: '',
      nextUrl: '' ,
      malformedUrl: false
    });

    onMounted(() => {
      const urlValue = router.currentRoute.value;
      const [confirmEmailUrl, nextUrl] = window.location.href.split("&nextUrl=");
      const confirmEmailUrlObj = queryToObj(confirmEmailUrl)
      const mode = confirmEmailUrlObj?.mode;
      state.opType = confirmEmailUrlObj?.mode || '';

      // Get the one-time code from the query parameter.
      state.actionCode = confirmEmailUrlObj?.oobCode ? confirmEmailUrlObj.oobCode : '';
      state.nextUrl = nextUrl !== undefined ?  nextUrl : ''
      if (mode === undefined || urlValue.query.oobCode === undefined) state.malformedUrl = true;
    });

    return {state};
  }
};
