import { createApp } from 'vue';
import App from './App.vue';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './registerServiceWorker';
import router from './router';
import store, { key } from './store';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import GvInputText from '@/views/components/GvInputText.vue';
import Button from 'primevue/button';
import ToastService from 'primevue/toastservice';
import Password from 'primevue/password';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import GVAutocomplete from "@/views/components/GvAddressAutocomplete.vue";
import NewGvCalendar from "@/views/components/NewGvCalendar.vue";

createApp(App)
    .use(store, key)
    .use(router)
    .use(PrimeVue)
    .use(ToastService)
    .component("NewGvCalendar", NewGvCalendar)
    .component('InputText', InputText)
    .component('Button', Button)
    .component("Password", Password)
    .component('TabView', TabView)
    .component('TabPanel', TabPanel)
    .component('gvInputText', GvInputText)
    .component('gvAutocomplete', GVAutocomplete)
    .mount('#app');
