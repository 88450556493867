
import {computed, defineComponent, onBeforeMount, ref} from 'vue';
import {Axios} from '@/Axios';
import {key} from '@/store';
import {useStore} from 'vuex';
import Dropdown from "primevue/dropdown";

export default defineComponent({
  components: {Dropdown},
  props: {
    errors: {type: Array, required: true},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    modelValue: {type: null}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    onBeforeMount(() => {
      Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/getShopsList`).then(result => {
        store.dispatch('setStoreListFromOnline', result.data);
      });
    });
    const store = useStore(key);

    const selectedShop = ref();
    const temporaryShops = computed(() => store.state.signupForm.storeList.poses.map(s => {
      return {
        code: s.shopData.shopId,
        name: s.shopData.signName + ' - ' + s.shopData.address
      }
    }));

    const emitShop = (shopId: string) => {
      emit('update:modelValue', {
        shop: store.state.signupForm.storeList.poses.filter(pos => pos.shopData.shopId === shopId)[0].shopData,
        shopName: store.state.signupForm.storeList.shopName
      });
    }
    return {temporaryShops, selectedShop, emitShop};
  },
});
