
import {computed, defineComponent, ref} from "vue";
import InputMask from "primevue/inputmask";

export default defineComponent({
  components: {InputMask},
  props: {
    errors: {type: Array, required: true},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    timeLabel: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, {emit}) {

    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    //Transform hour input number to string with 2 digits
    function getOutputHourString(hourNum: number) {
      return hourNum < 10 ? `0${hourNum}` : hourNum > 23 ? `23` : hourNum;
    }

    //Transform minutes input number to string with 2 digits
    function getOutputMinuteString(minuteNum: number) {
      return minuteNum < 10
          ? `0${minuteNum}`
          : minuteNum > 59
              ? `59`
              : minuteNum;
    }

    //Set max time to 23:59 if user set hour > 23 or minute > 59
    const setMaxTime = (input: { target: { value: string } }) => {
      if (input.target.value !== "") {
        //  split time string into hours and minutes
        const [insertedHours, insertedMinutes] = input.target.value.split(":");
        //convert hours and minutes to numbers
        const hourNum = parseInt(insertedHours);
        const minuteNum = parseInt(insertedMinutes);

        //   set outputHourString with leading 0 if hours is lowe than 10 and 23 if bigger than 23
        const outputHourString = getOutputHourString(hourNum);
        // set outputMinuteString with leading 0 if minutes is lower than 10 and 59 if bigger than 59
        const outputMinuteString = getOutputMinuteString(minuteNum);
        // set time to outputTimeString
        inputValue.value = `${outputHourString}:${outputMinuteString}`;
      }
    };

    return{inputValue, setMaxTime}
  },
})
