<template>
  <!-- header component -->
  <main class="main-div">
    <Header/>
    <router-view/>
  </main>
  <!--  <router-view />-->
  <!-- footer component -->
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.main-div {
  width: 100%;
  min-height: 100%;
  font-family: 'Raleway', sans-serif;
}

#app {
  min-width: 100vw;
  height: 100vh;
}

body {
  height: 100vh;
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.gv-button {
  background-color: #3dae2b !important;
  border-color: #3dae2b !important;
  border-radius: 0.8rem !important;
}

.gv-button.p-button-outlined {
  background-color: #ffffff !important;
  color: #38992b !important;
}

.gv-button:enabled:enabled:hover,
.gv-button:enabled:enabled:active {
  background: #38992b !important;
  border-color: #38992b !important;
}

.gv-button.p-button-outlined:enabled:enabled:hover,
.gv-button.p-button-outlined:enabled:enabled:active {
  color: #fff !important;
}

p a {
  color: #38992b;
}

.gv-button.p-button-link {
  background: transparent !important;
}

.gv-button.p-button-link:enabled:hover,
.gv-button.p-button-link:enabled:active {
  background: transparent !important;
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus {
  border-color: #3dae2b !important;
  box-shadow: 0 0 0 0.2rem #e7ffef !important;
}

.delete_icon {
  background: #ffb823 !important;
  border-color: #ffb823 !important;
}

.delete_icon:enabled:enabled:hover,
.delete_icon:enabled:enabled:active {
  background: #ffb823 !important;
  border-color: #ffb823 !important;
}

.cancel_button {
  background: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
  color: black !important;
  border-radius: 0.8rem !important;
}

.cancel_button:focus {
  box-shadow: none !important;
}

.cancel_button:enabled:enabled:hover,
.cancel_button:enabled:enabled:active {
  background: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
  color: black !important;
}

:root {
  --primary-color: #38992b !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #e7ffef !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #3dae2b !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: rgba(61, 174, 43, 0.1) !important;
  border-color: rgba(61, 174, 43, 0.1) !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #3dae2b !important;
}

.large-icon {
  font-size: 4em !important;
  color: #495057;
}

.secondary-gv-input .p-inputnumber-button-group .p-button,
.secondary-gv-input .p-inputnumber-button-group .p-button:hover,
.secondary-gv-input .p-datepicker-trigger,
.secondary-gv-input .p-datepicker-trigger:enabled:hover,
.secondary-gv-input .p-datepicker-trigger:enabled:active {
  background-color: white;
  color: gray;
  border: solid 0.5px lightgray;
  border-left: none;
}

</style>
<script>
import 'primeflex/primeflex.css';
import Header from '@/views/components/Header/Header';
import {defineComponent} from "vue";
import {auth} from "@/firebase";

export default defineComponent({
  components: {Header},
  setup() {
    auth.onAuthStateChanged(async user => {
      const token = await user?.getIdToken()
      if(token){
       window.localStorage.setItem("token", token)
      } else {
        window.localStorage.clear()
      }
    })
  }
});
</script>
