
import Card from 'primevue/card';
import GvInputText from '@/views/components/GvInputText.vue';
import {computed, defineComponent, onBeforeMount, reactive, ref, watch} from 'vue';
import {email, helpers, minLength, required, sameAs} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {useStore} from 'vuex';
import {key} from '@/store';
import {passwordRegex} from '@/utils';
import {Axios} from '@/Axios';
import GvSpinner from '@/views/components/GvSpinner.vue';
import {useToast} from 'primevue/usetoast';
import {useRouter} from "vue-router";
import GVPassword from "@/views/components/GVPassword.vue";

export default defineComponent({
  components: {GVPassword, Card, GvInputText, GvSpinner},
  emits: ['canGoForward'],
  props: {
    fetchData: {type: Boolean, default: false},
    disableFields: {type: Boolean, default: false},
    showAvatar: {type: Boolean, default: false}
  },
  setup(props, {emit}) {

    const isLoading = ref(false)

    onBeforeMount(() => {
      if (props.fetchData) {
        isLoading.value = true
        Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/getUserData`)
            .then(result => {
              const data = result.data;
              v$.value.role.$model = data.role;
              v$.value.phoneNumber.$model = data.phoneNumber || '';
              v$.value.email.$model = data.email;
              v$.value.firstName.$model = data.firstName;
              v$.value.lastName.$model = data.lastName;
            })
            .catch(() => {
              toast.add({
                severity: 'error',
                summary: "Errore nell'ottenere le informazioni sull'azienda",
                detail: '',
                life: 5000
              });
            }).finally(() => isLoading.value = false);
      }

    });

    const router = useRouter();
    const store = useStore(key);
    const state = reactive({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: ''
    });
    const toast = useToast();

    const rules = computed(() => ({
      firstName: {required: helpers.withMessage('Questo campo è obbligatorio', required)},
      lastName: {required: helpers.withMessage('Questo campo è obbligatorio', required)},
      role: {},
      phoneNumber: {
        startWithIntCode: helpers.withMessage('Il numero di telefono deve iniziare con +39', (value: string) => value.startsWith('+39') || value === ""),
      },
      email: {
        email: helpers.withMessage("L'indirizzo email non é valido", email),
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      ...(!props.fetchData && {

        password: {
          minLength: helpers.withMessage('Deve contenere almeno 8 caratteri', minLength(8)),
          mustBeValid: helpers.withMessage('Deve contenere almeno un carattere maiuscolo, 1 minuscolo e uno speciale', helpers.regex(passwordRegex)),
          required: helpers.withMessage('Questo campo è obbligatorio', required)
        },
        confirmPassword: {
          required: helpers.withMessage('Questo campo è obbligatorio', required),
          sameAsPassword: helpers.withMessage('Deve corrispondere alla password', sameAs(state.password))
        }
      })
    }));
    const v$ = useVuelidate(rules, state);

    const resetPassword = () => {
      router.push('forgot-password')
    }

    watch(
        () => v$.value,
        newValue => {
          store.commit('updateUserInfo', {
            firstName: newValue.firstName.$model,
            lastName: newValue.lastName.$model,
            phoneNumber: newValue.phoneNumber.$model,
            email: newValue.email.$model,
            password: newValue.password?.$model,
            confirmPassword: newValue.confirmPassword?.$model,
            role: newValue.role.$model
          });
          emit('canGoForward', !newValue.$invalid);
        }
    );

    return {v$, store, props, resetPassword, isLoading};
  }
});
