import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "p-d-flex p-flex-row",
  style: {"gap":"1em"}
}
const _hoisted_2 = {
  class: "p-d-flex p-flex-column",
  style: {"flex-grow":"1","max-width":"49%"}
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  class: "p-d-flex p-flex-column",
  style: {"flex-grow":"1","max-width":"49%"}
}
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputMask = _resolveComponent("InputMask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: "p-text-bold p-mb-2"
      }, _toDisplayString(_ctx.dataLabel), 9 /* TEXT, PROPS */, _hoisted_3),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
        "date-format": "dd.mm.yy",
        "min-date": _ctx.minDate,
        "max-date": _ctx.maxDate,
        "number-of-months": _ctx.deviceType() === 'mobile' ? 1: 2,
        onDateSelect: _ctx.updateParentValue
      }, null, 8 /* PROPS */, ["modelValue", "min-date", "max-date", "number-of-months", "onDateSelect"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
        return (_openBlock(), _createElementBlock("div", {
          key: error.$message
        }, [
          (_ctx.isDirty)
            ? (_openBlock(), _createElementBlock("small", {
                key: 0,
                id: _ctx.id,
                class: "p-error"
              }, _toDisplayString(error.$message), 9 /* TEXT, PROPS */, _hoisted_4))
            : _createCommentVNode("v-if", true)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: "p-text-bold p-mb-2"
      }, _toDisplayString(_ctx.timeLabel), 9 /* TEXT, PROPS */, _hoisted_6),
      _createVNode(_component_InputMask, {
        mask: "99:99",
        modelValue: _ctx.timeRef,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.timeRef) = $event)),
        "slot-char": "hh:mm",
        onFocusout: _ctx.setMaxTime
      }, null, 8 /* PROPS */, ["modelValue", "onFocusout"])
    ])
  ]))
}