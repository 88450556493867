import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-12 p-md-6 p-lg-6" }
const _hoisted_3 = { class: "p-col-12 p-md-6 p-lg-3" }
const _hoisted_4 = { class: "p-col-12 p-md-6 p-lg-3" }
const _hoisted_5 = { class: "p-col-12 p-md-6 p-lg-3" }
const _hoisted_6 = { class: "p-col-12 p-md-6 p-lg-3" }
const _hoisted_7 = { class: "p-col-12 p-md-6 p-lg-3" }
const _hoisted_8 = { class: "p-col-12 p-md-6 p-lg-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GvShops = _resolveComponent("GvShops")!
  const _component_GvInputNumber = _resolveComponent("GvInputNumber")!
  const _component_GvVehicles = _resolveComponent("GvVehicles")!
  const _component_GvCalendarMultipleSelection = _resolveComponent("GvCalendarMultipleSelection")!
  const _component_GvInputTime = _resolveComponent("GvInputTime")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_GvShops, {
        "data-testid": "shop-input",
        id: "shop",
        modelValue: _ctx.v$.shop.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.shop.$model) = $event)),
        errors: _ctx.v$.shop.$silentErrors,
        "is-dirty": _ctx.v$.shop.$dirty
      }, null, 8 /* PROPS */, ["modelValue", "errors", "is-dirty"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_GvInputNumber, {
        "field-name": "Riders:",
        class: "secondary-gv-input",
        "show-buttons": true,
        min: 1,
        max: 8,
        "data-testid": "riders-input",
        id: "riders",
        modelValue: _ctx.v$.riders.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.riders.$model) = $event)),
        errors: _ctx.v$.riders.$silentErrors,
        "is-dirty": _ctx.v$.riders.$dirty
      }, null, 8 /* PROPS */, ["modelValue", "errors", "is-dirty"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_GvVehicles, {
        "data-testid": "vehicle-input",
        id: "vehicle",
        modelValue: _ctx.v$.vehicle.$model,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.vehicle.$model) = $event)),
        errors: _ctx.v$.vehicle.$silentErrors,
        "is-dirty": _ctx.v$.vehicle.$dirty
      }, null, 8 /* PROPS */, ["modelValue", "errors", "is-dirty"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_GvCalendarMultipleSelection, {
        "field-name": "Seleziona una o piu date",
        modelValue: _ctx.v$.dates.$model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.dates.$model) = $event)),
        errors: [],
        id: "calendar"
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_GvInputTime, {
        modelValue: _ctx.v$.startTime.$model,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.startTime.$model) = $event)),
        "time-label": "Inserisci ora inizio turno",
        "is-dirty": _ctx.v$.startTime.$dirty,
        errors: _ctx.v$.startTime.$silentErrors,
        id: "stat-time"
      }, null, 8 /* PROPS */, ["modelValue", "is-dirty", "errors"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_GvInputNumber, {
        class: "secondary-gv-input",
        "show-buttons": true,
        min: 2,
        modelValue: _ctx.v$.hoursDuration.$model,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$.hoursDuration.$model) = $event)),
        errors: _ctx.v$.hoursDuration.$silentErrors,
        "is-dirty": _ctx.v$.hoursDuration.$dirty,
        "field-name": "Durata ore",
        id: "hours-duration"
      }, null, 8 /* PROPS */, ["modelValue", "errors", "is-dirty"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_GvInputNumber, {
        class: "secondary-gv-input",
        "show-buttons": true,
        step: 15,
        min: 0,
        max: 45,
        modelValue: _ctx.v$.minutesDuration.$model,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$.minutesDuration.$model) = $event)),
        "field-name": "Durata minuti",
        errors: _ctx.v$.minutesDuration.$silentErrors,
        id: "minutes-duration"
      }, null, 8 /* PROPS */, ["modelValue", "errors"])
    ])
  ]))
}