
import {defineComponent, ref} from 'vue';
import {Vehicle, vehicle_type} from '@/types';
import Dropdown from "primevue/dropdown";

export default defineComponent({
  components: {Dropdown},
  setup() {
    const filteredVehicles = ref();
    const selectedVehicle = ref();
    const vehicleOptions: Vehicle[] = [
      {
        id: "private_car",
        name: "Auto Personale"
      },
      {
        id: "govolt_scooter",
        name: "Scooter GoVolt"
      },
      {
        id: "private_scooter",
        name: "Scooter personale"
      },
      {
        id: "govolt_van",
        name: "Furgone GoVolt"
      }
    ]

    return {filteredVehicles, vehicleOptions, selectedVehicle};
  },

  props: {
    errors: {type: Array, required: true},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    modelValue: {type: null}
  },
  emits: ['update:modelValue'],
  methods: {
    emitVehicle(id: vehicle_type) {
      const vehicle: Vehicle = this.vehicleOptions.filter(vehicle => vehicle.id === id)[0]
      this.$emit('update:modelValue', vehicle);
    }
  }
});
