import {InjectionKey} from 'vue';
import {createStore, Store} from 'vuex';
import {
    Appointment,
    AppointmentFieldGroup,
    AppointmentSpec,
    LegalEntityType,
    ShopPos,
    Shops,
    signupForm,
    User,
    UserInfo
} from '@/types';
import {AreTheseObjectsEqual} from '@/utils';
import {uuid} from 'vue-uuid';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {
    signupForm: signupForm;
    appointments: AppointmentSpec;
    user: User;
}

// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<State>> = Symbol();


const initialState = () => {
    return {
        user: {
            fullName: null
        },
        signupForm: {
            storeList: {shopName: '', poses: [] as ShopPos[]} as Shops,
            legalEntity: {
                legalEntityId: '',
                name: '',
                legalAddress: '',
                city: '',
                zipCode: '',
                prov: '',
                vat: '',
                ssn: '',
                sdi: '',
                legalEmail: '',
                administrativeEmail: '',
                signName: ''
            },
            userInfo: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                role: ''
            },
        },
        appointments: {
            list: [] as AppointmentFieldGroup[]
        }
    }
}

export default createStore<State>({
    state: initialState,
    mutations: {
        updateSignupForm(state, payload: { fieldName: string; value: string }) {
            state.signupForm = {
                ...state.signupForm,
                [payload.fieldName]: payload.value
            };
        },
        updateStoreList(state, shopList: Shops) {
            state.signupForm.storeList = shopList;
        },
        updateLegalEntity(state, legalEntity: LegalEntityType) {
            state.signupForm.legalEntity = {
                ...state.signupForm.legalEntity,
                ...legalEntity
            };
        },
        updateUserInfo(state, userInfo: UserInfo) {
            if (userInfo.phoneNumber === "") {
                userInfo.phoneNumber = undefined
            }
            state.signupForm.userInfo = {
                ...state.signupForm.userInfo,
                ...userInfo
            };
        },
        updateAppointmentList(state, appointmentList: AppointmentFieldGroup[]) {
            state.appointments.list = appointmentList;
        },
        updateFullNameUser(state, fullName: string) {
            state.user.fullName = fullName;
        },
        reset(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                // @ts-ignore
                state[key] = s[key]
            })
        }
    },
    actions: {
        updateUserInfo(context, payload: UserInfo) {
            if (context.state.signupForm.legalEntity.administrativeEmail === '') {
                context.commit('updateLegalEntity', {
                    administrativeEmail: payload.email
                });
            }
            context.commit('updateUserInfo', payload);
        },
        setUserInfoFromOnline(context, payload: UserInfo) {
            context.commit('updateUserInfo', payload);
        },
        updateLegalEntity(context, payload: LegalEntityType) {
            if (payload.ssn === '') {
                payload.ssn = payload.vat;
            }
            context.commit('updateLegalEntity', payload);
        },
        setLegalEntityFromOnline(context, payload: LegalEntityType) {
            context.commit('updateLegalEntity', payload);
        },
        setStoreListFromOnline(context, payload: ShopPos[]) {
            const formattedShopList = {
                poses: payload.map((pos: ShopPos) => {
                    return {
                        shopData: pos,
                        isInvalid: false
                    };
                })
            };
            context.commit('updateStoreList', formattedShopList);
        },
        deleteShop(context, payload: string) {
            const filteredStores = {
                shopName: context.state.signupForm.storeList.shopName,
                poses: context.state.signupForm.storeList.poses.filter((shop: ShopPos) => shop.shopData.shopId !== payload)
            };
            context.commit('updateStoreList', filteredStores);
        },
        async updateSingleShop(context, payload: ShopPos) {
            const currentShopList = context.state.signupForm.storeList.poses;

            //Here will be stored the shop with the same ID
            const sameIDStore: { index: number; shop: ShopPos } = {index: 0, shop: {} as ShopPos};

            await currentShopList.forEach((shop: ShopPos, idx: number) => {
                if (shop.shopData.shopId === payload.shopData.shopId) {
                    sameIDStore.shop = shop;
                    sameIDStore.index = idx;
                }
            });
            if (!AreTheseObjectsEqual(sameIDStore.shop.shopData, payload.shopData) || payload.isInvalid !== sameIDStore.shop.isInvalid) {
                currentShopList[sameIDStore.index] = payload;
                context.commit('updateStoreList', {
                    shopName: context.state.signupForm.storeList.shopName,
                    poses: currentShopList
                });
            }
        },
        addEmptyStore(context) {
            const newShop: ShopPos = {
                isInvalid: true,
                shopData: {
                    shopId: uuid.v4(),
                    signName: '',
                    phoneNumber: '',
                    address: '',
                    referrer: ''
                }
            };
            const currentShopList = context.state.signupForm.storeList;
            const newShopList: Shops = {
                shopName: currentShopList.shopName,
                poses: [newShop].concat(currentShopList.poses)
            };
            context.commit('updateStoreList', newShopList);
        },
        setAppointmentsFromOnline(context, payload: Appointment[]) {
            const formattedAppointmentList = payload.map(appointment => {
                return {
                    appointmentData: appointment,
                    isInvalid: false
                };
            });
            context.commit('updateAppointmentList', formattedAppointmentList);
        }
    },
    getters: {
        getShopList: state => state.signupForm.storeList.poses.map(shop => shop),

        getPlainShopForUpdate: state => {
            return {
                shopName: state.signupForm.storeList.shopName,
                poses: state.signupForm.storeList.poses.map(shop => shop.shopData)
            };
        },
        getPlainShopList: state => state.signupForm.storeList.poses.map(shop => shop.shopData),
        getAreShopsValid: state => state.signupForm.storeList.poses.every(shop => !shop.isInvalid),
        getAppointments: state => state.appointments.list.map(appointment => appointment.appointmentData)
    },
    modules: {}
});
