
import {computed, defineComponent, onBeforeMount, ref, watch} from 'vue';
import Card from 'primevue/card';

import ShopCardInsertion from '@/views/components/ShopCardInsertion.vue';
import {useStore} from 'vuex';
import {key} from '@/store';
import {Axios} from '@/Axios';
import GvSpinner from '@/views/components/GvSpinner.vue';
import {useToast} from 'primevue/usetoast';
import {useRouter} from 'vue-router';

export default defineComponent({
  components: {ShopCardInsertion, Card, GvSpinner},
  emits: ['canGoForward'],
  props: {
    fetchData: {type: Boolean, default: false},
    disableFields: {type: Boolean, default: false},
    isInProfileView: {type: Boolean, default: false},
    showAvatar: {type: Boolean, default: false}
  },
  setup(props, {emit}) {
    emit('canGoForward', true);
    const router = useRouter();
    const isLoading = ref(false);

    onBeforeMount(() => {
      if (props.fetchData) {
        isLoading.value = true;
        Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/getShopsList`)
            .then(result => {
              store.dispatch('setStoreListFromOnline', result.data);
            })
            .catch(() => {
              toast.add({
                severity: 'error',
                summary: "Errore nell'ottenere la lista negozi",
                detail: '',
                life: 5000
              });
            })
            .finally(() => isLoading.value = false)
      }

    });
    const store = useStore(key);
    const toast = useToast();
    const storeValidity = computed(() => store.getters.getAreShopsValid)

    watch(storeValidity, newValue => {
      emit('canGoForward', !newValue);
    });

    const deleteShop = (id: string) => {
      store.dispatch('deleteShop', id);
    };

    const addNewEmptyShop = () => {
      store.dispatch('addEmptyStore');
    };

    const back = () => {
      router.back();
    };

    const isValid = (val: boolean) => {
      emit('canGoForward', val);
    };

    return {
      isLoading,
      storeList: computed(() => store.getters.getPlainShopList),
      store,
      addNewEmptyShop,
      deleteShop,
      props,
      back,
      isValid
    };
  }
});
