
import {computed, defineComponent} from 'vue';
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
  components: {InputNumber},
  props: {
    step: {type: Number, required: false, default: 1},
    min: {type: Number},
    max: {type: Number},
    showButtons: {type: Boolean, default: false},
    fieldName: {type: String, required: true},
    errors: {type: Array, required: true},
    placeholder: {type: String, default: ''},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    modelValue: {
      type: Number
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    return {
      inputValue
    }
  }
});
