
import {computed, defineComponent, ref, watchEffect} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import {differenceInCalendarWeeks, formatISO, intervalToDuration, isBefore} from 'date-fns'

import {useToast} from 'primevue/usetoast';

import Toast from 'primevue/toast';
import Card from 'primevue/card';
import GvSpinner from '@/views/components/GvSpinner.vue';
import AddAppointment from '@/views/components/AddAppointment.vue';
import ExistingAppointments from './existing.vue';
import Divider from 'primevue/divider';


import {AppointmentInput, days} from '@/types';
import {key} from '@/store';
import {useStore} from "vuex";
import {GvAxios} from "@/GvAxios";
import {Axios} from "@/Axios";
import {sortDates} from "@/utils";
import {formatDate} from "@fullcalendar/core";
import {DateTime} from "luxon";

export default defineComponent({
  components: {Toast, GvSpinner, Card, AddAppointment, ExistingAppointments, Divider},
  setup() {
    const store = useStore(key);
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const isLoading = ref(false);
    const appointments = ref<{ index: number; valid: boolean; data?: AppointmentInput }[]>([{index: 0, valid: false}]);
    const isFormInvalid = ref(true);
    const isPastDate = computed(() => {
      const {start} = router.currentRoute.value.query
      return start !== undefined ? isBefore(new Date(start as string), new Date()) : false
    })

    const getTitle = computed(() => {
      if (!isPastDate.value) return 'Compila nuovo ordine'
      else {
        const {start} = router.currentRoute.value.query
        if (start !== undefined) {
          return start + ' - Effettuato'
        } else return ''
      }

    })

    const addAppointment = () => {
      appointments.value.push({index: appointments.value.length, valid: false});
    };

    const removeAppointment = (index: number) => {
      appointments.value.splice(index);
    };

    const manageResult = (result: { index: number; valid: boolean; data: AppointmentInput }) => {
      appointments.value[result.index] = result;
    };

    watchEffect(() => {
      isFormInvalid.value = appointments.value.some(a => !a.valid);
    });

    const saveAppointments = () => {
      const payload = new Array<unknown>();
      appointments.value
          .filter(a => a.data)
          .forEach(a => {
            const shopData = store.state.signupForm.storeList.poses.filter(shop => shop.shopData.shopId === a.data?.shop.shop.shopId)[0]
            if (a.data && a.data.dates.length > 0) {
              const sortedDates = sortDates(a.data.dates)
              const firstDate = sortedDates[0];
              const lastDate = sortedDates[a.data.dates.length - 1];
              const numberOfWeeks = firstDate === lastDate ? 0 : differenceInCalendarWeeks(firstDate.toJSDate(), lastDate.toJSDate()) + 1
              const payloadEntry = {
                event: {
                  shop: {
                    name: shopData.shopData.signName,
                    address: shopData.shopData.address,
                    shopId: shopData.shopData.shopId
                  },
                  dates: a.data?.dates.map((day) => {
                    day.setHours(Number(a.data?.startTime?.split(':')[0]))
                    day.setMinutes(Number(a.data?.startTime?.split(':')[1]))
                    return day
                  }),
                  startTime: a.data?.startTime,
                  duration: {
                    hh: a.data.hoursDuration,
                    mm: a.data.minutesDuration
                  },
                },
                vehicleType: a.data?.vehicle.id,
                riders: a.data?.riders,
                note: "",
                totWeeks: numberOfWeeks,
              };
              payload.push(payloadEntry);
            }
          });

      isLoading.value = true;

      const promises = payload.map(appo => Axios.post(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/addAppointment`, appo));
      Promise.all(promises)
          .then(() => {
            toast.add({
              severity: 'success',
              summary: 'Appuntamento creato correttamente',
              detail: '',
              life: 5000
            });
          }).catch(err => {
        console.error(err);
        toast.add({
          severity: 'error',
          summary: 'Impossibile salvere appuntamento',
          detail: '',
          life: 5000
        });
      }).finally(() => (isLoading.value = false));
    };

    const backToCSAC = () => router.push('/csac');

    return {
      isLoading,
      appointments,
      startFromRoute: route?.query.start,
      endFromRoute: route?.query.end,
      isFormInvalid,
      manageResult,
      addAppointment,
      removeAppointment,
      saveAppointments,
      backToCSAC,
      isPastDate,
      getTitle
    };
  }
});
