
import UserProfileInfoManagement from '@/views/components/UserProfileInfoManagement.vue';
import {defineComponent, ref} from 'vue';
import {Axios} from '@/Axios';
import {useStore} from 'vuex';
import {key} from '@/store';
import Toast from 'primevue/toast';

import {useToast} from 'primevue/usetoast';
import {signupErrors} from "@/views/errors";
import GvSpinner from "@/views/components/GvSpinner.vue";

export default defineComponent({
  components: {UserProfileInfoManagement, Toast, GvSpinner},
  props: {
    isInProfileView: {type: Boolean, default: false}
  },
  setup(props: { isInProfileView: boolean; }) {
    const toast = useToast();
    const isInEditMode = ref(false);
    const store = useStore(key);
    const isLoading = ref(false)
    const canSave = ref(false)

    const updatedData = () => {
      const dataToUpdate = store.state.signupForm.userInfo
      isLoading.value = true
      if (props.isInProfileView) {
        delete dataToUpdate.password;
        delete dataToUpdate.confirmPassword
      }
      Axios.post(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/updateUserData`, dataToUpdate)
          .then(() => {
            toast.add({
              severity: 'success',
              summary: 'Modifiche salvate',
              detail: '',
              life: 5000
            });
            isInEditMode.value = false;
          })
          .catch(err => {
            console.error(err);
            toast.add({
              severity: 'error',
              summary: 'Errore salvataggio modifiche',
              detail: err.data.code ? signupErrors[err.data.code] : '',
              life: 5000
            });
            Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/getUserData`).then(result => {
              store.commit('setUserInfoFromOnline', result.data);
            });
          }).finally(() => isLoading.value = false);
    }


    const cancelChanges = () => {
      isInEditMode.value = false;
    };

    const enableEditing = () => {
      isInEditMode.value = true;
    };

    const changeCanSaveStatus = (value: boolean) => {
      canSave.value = value
    }

    return {isInEditMode, enableEditing, cancelChanges, updatedData, isLoading, changeCanSaveStatus, canSave};
  }
});
