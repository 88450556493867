import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "p-text-bold p-mb-2"
    }, _toDisplayString(_ctx.fieldName), 9 /* TEXT, PROPS */, _hoisted_2),
    _createVNode(_component_Calendar, _mergeProps(_ctx.$attrs, {
      "show-time": true,
      id: _ctx.id,
      "date-format": _ctx.dateFormat,
      "show-icon": _ctx.showIcon,
      "min-date": _ctx.minDate,
      "max-date": _ctx.maxDate,
      "model-value": _ctx.modelValue,
      placeholder: _ctx.placeholder,
      onDateSelect: _ctx.emitDate
    }), null, 16 /* FULL_PROPS */, ["id", "date-format", "show-icon", "min-date", "max-date", "model-value", "placeholder", "onDateSelect"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        key: error.$message
      }, [
        (_ctx.isDirty)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              id: _ctx.id,
              class: "p-error"
            }, _toDisplayString(error.$message), 9 /* TEXT, PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}