import {auth} from "@/firebase";
import {decode} from "jsonwebtoken";
import {DateTime} from "luxon";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const AreTheseObjectsEqual = (obj1: any, obj2: any): boolean => {
    const keys = Object.keys(obj1);

    for (const key in keys) {
        if (obj1[key] === obj2[key]) return false;
    }

    return true;
};

const performLogout = (): Promise<void> => {
        return auth.signOut()
            .then(() => {
                // window.localStorage.clear()
                window.location.reload()
            })
}

const queryToObj = (url: string): Record<string, string> | undefined => {
    const pairs = url.split("?")[1].split("&")

    if (pairs !== undefined && Array.isArray(pairs)) {
        const result: any = {}
        pairs.forEach((pair: any) => {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        })
        return JSON.parse(JSON.stringify(result));
    } else {
        return undefined
    }

}
const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
};

const userRegistrationComplete = (user: any): Promise<boolean> => {

    //Get idToken from user
    return user.getIdToken()
        .then((idToken: string) => {
            //    decode accessToken
            const decodedToken = decode(idToken, {json: true});
//    check if decodedToken is null
            if (decodedToken === null) {
                console.error("decodedToken is null")
                return false;
            } else {
                //    check if accessTokens contains legalEntity attribute
                return decodedToken.legalEntity !== undefined;
            }

        }).catch((error: any) => {
        console.error(error)
        return false;
    })
}

const sortDates = (dates: Date[]) => {
    return dates.map((date: Date) => DateTime.fromJSDate(date).toMillis()).sort((a: number, b: number) => a-b).map((date: number) => DateTime.fromMillis(date))
}

export {passwordRegex, AreTheseObjectsEqual, performLogout, queryToObj, userRegistrationComplete, deviceType, sortDates};
