
import {computed, defineComponent} from "vue";
import Calendar from "primevue/calendar";
import {DateTime} from "luxon";

export default defineComponent({
  components: {Calendar},
  props: {
    fieldName: {type: String, required: true},
    errors: {type: Array, required: true},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    modelValue: {
      type: Array<Date>
    }
  },
  setup (props, {emit}) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    return {
      inputValue
    }
  }
})
