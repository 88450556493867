const signinErrors: Record<string, string> = {
    'auth/user-not-found': "L'email e/o la password non sono corretti",
    'auth/wrong-password': "L'email e/o la password non sono corretti",
    'auth/user-disabled': "L'utente non é abilitato",
    'auth/invalid-email': "L'indirizzo email é mal formattato",
    'auth/email-not-verified': "L'indirizzo email non é stato verififcato. Per favore segua le istruzioni ricevute per email",
    'auth/unknown': "Opss.. questo é un po' imbarazzante. Non siamo riusciti a processare la richiesta"
};

export { signinErrors };
