
	import { computed, ref } from "vue";
	import { useRouter } from "vue-router";
	import Steps from "primevue/steps";
	import { key } from "@/store";
	import { useStore } from "vuex";
	import { ShopPos } from "@/types";
	import GvSpinner from "@/views/components/GvSpinner.vue";
	import Toast from "primevue/toast";
	import { useToast } from "primevue/usetoast";
	import { signupErrors } from "@/views/errors";
	import { Axios } from "@/Axios";
	import { auth } from "@/firebase";

	export default {
		components: { Steps, GvSpinner, Toast },

		setup() {
			const store = useStore(key);
			const router = useRouter();
			const signupCompleted = ref(false);
			const isLoading = ref(false);
			const currentStep = ref(0);
			const disabledButton = ref(true);
			const items = ref([
				{
					label: "Dati azienda",
					to: "/signupCompletion/company",
				},
				{
					label: "Negozi",
					to: "/signupCompletion/shops",
				},
			]);
			const toast = useToast();
			const setNextButtonEnableStatus = (new_data: boolean): void => {
				disabledButton.value = new_data;
			};
			const nextPage = () => {
				currentStep.value++;
				router.push(items.value[currentStep.value].to);
			};
			const prevPage = () => {
				disabledButton.value = false;
				currentStep.value--;
				router.push(items.value[currentStep.value].to);
			};

			const submitForSignup = () => {
				isLoading.value = true;
				Axios.post(
					`${process.env
						.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/completeRegistration`,
					{
						shopList: store.state.signupForm.storeList.poses.map(
							(storeElement: ShopPos) => storeElement.shopData
						),
						legalEntity: store.state.signupForm.legalEntity,
					}
				)
					.then(() => {
						signupCompleted.value = true;
						setTimeout(() => {
							auth.currentUser
								?.getIdToken(true)
								.then(() => router.push("/csac"))
								.catch((err) => console.error(err));
						}, 3000);
					})
					.catch((err) => {
						console.error(err);
						toast.add({
							severity: "error",
							summary: "Signup completion failure",
							detail: signupErrors[err.errorInfo.code]
								? signupErrors[err.errorInfo.code]
								: "Something went wrong",
							life: 5000,
						});
					})
					.finally(() => {
						isLoading.value = false;
					});
			};

			const forwardButton = computed(() => {
				return {
					text:
						currentStep.value !== items.value.length - 1
							? "Prossimo"
							: "Conferma",
					action:
						currentStep.value !== items.value.length - 1
							? nextPage
							: submitForSignup,
				};
			});
			return {
				items,
				currentStep,
				nextPage,
				prevPage,
				setNextButtonEnableStatus,
				disabledButton,
				signupCompleted,
				forwardButton,
				isLoading,
			};
		},
	};
