
import Card from 'primevue/card';
import GvSpinner from '@/views/components/GvSpinner.vue';
import {defineComponent, onBeforeMount, ref} from 'vue';
import {DateTime} from 'luxon';
import {key} from '@/store';
import {useStore} from 'vuex';
import {Appointment} from '@/types';

export default defineComponent({
  components: {Card, GvSpinner},
  props: {
    appointmentDate: {type: String, required: true}
  },
  setup(props: { appointmentDate: string }) {
    onBeforeMount(() => {
      setAppointments()
    });
    const store = useStore(key);
    const isLoading = ref(false);

    const existingAppointments = ref();

    const setAppointments = () => {
      const storeAppointments: Appointment[] = JSON.parse(JSON.stringify(store.getters.getAppointments))
      existingAppointments.value = storeAppointments.filter(t => +DateTime.fromISO(t.startTime.toString()).startOf('day') === +DateTime.fromISO(props.appointmentDate).startOf('day'));
    }

    const getTitle = (item: Appointment) => {
      const itemEnd = DateTime.fromISO(item.endTime.toString());
      const appointmentStatus = DateTime.now() > itemEnd ? 'Completato' : 'Programmato';
      return `${formatDate(item.startTime)} - ${appointmentStatus}`;
    };

    const formatDate = (date: string | Date) => {
      if (typeof date === 'string') {
        return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
      } else {
        return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy");
      }
    };

    const formatTime = (date: string) => {
        return DateTime.fromISO(date).toFormat("HH:mm");
    };

    return {
      isLoading,
      existingAppointments,
      formatDate,
      formatTime,
      getTitle
    };
  }
});
