import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "p-text-bold p-mb-2"
    }, "Veicolo:", 8 /* PROPS */, _hoisted_2),
    _createVNode(_component_Dropdown, _mergeProps({
      "model-value": _ctx.modelValue,
      options: _ctx.vehicleOptions,
      optionLabel: "name",
      optionValue: "id",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitVehicle($event.value))),
      id: _ctx.id
    }, _ctx.$attrs, {
      modelValue: _ctx.selectedVehicle,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedVehicle) = $event)),
      placeholder: "Seleziona un veicolo"
    }), null, 16 /* FULL_PROPS */, ["model-value", "options", "id", "modelValue"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        key: error.$message
      }, [
        (_ctx.isDirty)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              id: _ctx.id,
              class: "p-error"
            }, _toDisplayString(error.$message), 9 /* TEXT, PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}