import {Axios} from "@/Axios";
import {IAddressDetails, IAddressSuggestion} from "@/types";

const AddressLookUp = (address: string) => {
   return Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/addressAutocompletion?addr=` + encodeURI(address), {
      transformResponse: (data) => {
         try {
            return JSON.parse(data) as IAddressSuggestion[];
         } catch (e) {
            console.error(e);
            return undefined
         }
      }
   })
}

const getAddressDetailsService = (magicKey: string) => {
   return Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/addressDetails?magicKey=` + encodeURI(magicKey), {
        transformResponse: (result) => {
           try {
              return JSON.parse(result) as IAddressDetails;
           } catch (e) {
              console.error(e);
              return undefined
           }
        }
   })
}

export {AddressLookUp, getAddressDetailsService}
