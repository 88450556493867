
import {computed, defineComponent, reactive, ref} from 'vue';
import {helpers, minLength, required, sameAs} from '@vuelidate/validators';
import {passwordRegex} from '@/utils';
import {useVuelidate} from '@vuelidate/core';
import GvInputText from '@/views/components/GvInputText.vue';
import {auth} from '@/firebase';
import {confirmPasswordReset, verifyPasswordResetCode} from 'firebase/auth';
import {useRouter} from 'vue-router';
import {confirmResetPasswordErrors, verifyPasswordResetCodeErrors} from '@/views/Auth/errors';
import Toast from 'primevue/toast';
import GvSpinner from '@/views/components/GvSpinner.vue';
import {useToast} from 'primevue/usetoast';
import GVPassword from "@/views/components/GVPassword.vue";

export default defineComponent({
  components: {GVPassword, GvInputText, Toast, GvSpinner},
  props: {
    actionCode: {type: String, default: ''}
  },
  setup(props) {
    const router = useRouter();
    const toast = useToast();
    const isLoading = ref(false);
    const resetPasswordCompleted = ref(false);
    const errorOccurred = ref(false);

    const passwordFields = reactive({
      password: '',
      confirmPassword: ''
    });

    const rules = computed(() => ({
      password: {
        minLength: helpers.withMessage('Deve contenere almeno 8 caratteri', minLength(8)),
        mustBeValid: helpers.withMessage('Deve contenere almeno un carattere maiuscolo, 1 minuscolo e uno speciale', helpers.regex(passwordRegex)),
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      confirmPassword: {
        required: helpers.withMessage('Questo campo è obbligatorio', required),
        sameAsPassword: helpers.withMessage('Deve corrispondere alla password', sameAs(passwordFields.password))
      }
    }));

    const goToLogin = () => router.push('/signin');

    const performResetPassword = () => {
      isLoading.value = true;

      verifyPasswordResetCode(auth, props.actionCode)
          .then(() => {
            confirmPasswordReset(auth, props.actionCode, passwordFields.password)
                .then(() => (resetPasswordCompleted.value = true))
                .catch(error => {
                  toast.add({
                    severity: 'error',
                    summary: 'Sorry',
                    detail: confirmResetPasswordErrors[error.code],
                    life: 5000
                  });
                });
          })
          .catch(error => {
            toast.add({
              severity: 'error',
              summary: 'Sorry',
              detail: verifyPasswordResetCodeErrors[error.code],
              life: 5000
            });
          })
          .finally(() => (isLoading.value = false));
    };

    const v$ = useVuelidate(rules, passwordFields);

    return {
      v$,
      props,
      errorOccurred,
      performResetPassword,
      resetPasswordCompleted,
      goToLogin,
      isLoading
    };
  }
});
