import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import Signin from '@/views/Signin/Signin.vue';
import ForgotPassword from '@/views/ForgotPassword/ForgotPassword.vue';
import Auth from '@/views/Auth/Auth.vue';
import NotFound from '@/views/NotFound/NotFound.vue';
import Profile from '@/views/Profile/Profile.vue';
import SimpleSignup from '@/views/SimpleSignup/SimpleSignup.vue';
import SignupCompletion from '@/SignupCompletion/SignupCompletion.vue';
import SignupCompletionCompany from '@/SignupCompletion/SignupCompletionCompany.vue';
import SignupCompletionShops from '@/SignupCompletion/SignupCompletionShops.vue';
import CSAC from '@/views/csac/csac.vue';
import AddAppointment from '@/views/csac/add.vue';
import {auth} from "@/firebase";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/signupCompletion',
        name: 'Signup Completion',
        component: SignupCompletion,
        children: [
            {
                path: 'company',
                component: SignupCompletionCompany
            },
            {
                path: 'shops',
                component: SignupCompletionShops
            }
        ],
        meta: {requireAuth: true}
    },
    {
        path: '/signup',
        name: 'SimpleSignup',
        component: SimpleSignup
    },
    {
        path: '/signin',
        alias: ['/'],
        name: 'Signin',
        component: Signin,
        beforeEnter: (to, from, next) => {
            if (sessionStorage.getItem('token-id') === null) next()
            else next({name: 'Customer Single Appointment Creation'})
        }
    },
    {
        path: '/forgot-password',
        name: 'Forgot password',
        component: ForgotPassword
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {requireAuth: true}
    },
    {
        path: '/csac',
        name: 'Customer Single Appointment Creation',
        component: CSAC,
        meta: {requireAuth: true}
    },
    {
        path: '/csac/add',
        name: 'CSAC add appointment',
        component: AddAppointment,
        meta: {requireAuth: true}
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    if(to.meta.requireAuth) {
        if (window.localStorage.getItem("token") !== null) next()
        else next({name: 'Signin'})
    } else next()
});

export default router;
