
import GvInputText from '@/views/components/GvInputText.vue';
import {computed, reactive, ref} from 'vue';
import {email, helpers, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import axios from 'axios';
import Toast from 'primevue/toast';
import {useToast} from 'primevue/usetoast';
import GvSpinner from '@/views/components/GvSpinner.vue';

export default {
  components: {GvSpinner, GvInputText, Toast},
  setup: function () {
    const toast = useToast();
    const isLoading = ref(false);
    const state = reactive({
      emailAddress: ''
    });
    const rules = computed(() => ({
      emailAddress: {
        email: helpers.withMessage("L'indirizzo email non é valido", email),
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      }
    }));
    const v$ = useVuelidate(rules, state);

    const showToast = (severity: string, summary: string, detail: string, life?: 5000) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: life
      });
    }

    const resetPassword = () => {
      isLoading.value = true;
      axios
          .post(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/resetPasswordEmailService`, {
            email: state.emailAddress
          })
          .then(() => {
            state.emailAddress = '';
            v$.value.$reset();
            showToast("success", "Fatto!", "Controlla in tuo indirizzo email per completare il reset password")
          })
          .catch(err => {
            console.error(err);
            showToast("error", "Errore", "Qualcosa é andato storto nel completare la tua richiesta. Contatta l'assistenza GoVolt")
          })
          .finally(() => (isLoading.value = false));
    };
    return {v$, resetPassword, isLoading};
  }
};
