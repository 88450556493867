import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "p-text-bold p-mb-2"
    }, _toDisplayString(_ctx.fieldName), 9 /* TEXT, PROPS */, _hoisted_2),
    _createVNode(_component_Calendar, {
      minDate: new Date(),
      locale: "it",
      "date-format": "dd/mm/yy",
      inputId: "multiple",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      selectionMode: "multiple",
      manualInput: false
    }, null, 8 /* PROPS */, ["minDate", "modelValue"])
  ]))
}