import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.props.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("v-if", true),
    (_ctx.props.isLoading)
      ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
          key: 1,
          class: "gv-progress-bar"
        }))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default")
  ], 64 /* STABLE_FRAGMENT */))
}