
import {computed, defineComponent, onBeforeMount, reactive, ref, watch} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {email, helpers, minLength, required} from '@vuelidate/validators';
import {useRouter} from 'vue-router';
import Card from 'primevue/card';
import GvInputText from '@/views/components/GvInputText.vue';
import {useStore} from 'vuex';
import {key} from '@/store';
import {Axios} from '@/Axios';
import {useToast} from 'primevue/usetoast';
import {IAddress, IAddressDetails, LegalEntityType} from '@/types';
import {AxiosResponse} from 'axios';
import GvSpinner from "@/views/components/GvSpinner.vue";
import GVAutocomplete from "@/views/components/GvAddressAutocomplete.vue";
import legalEntityEdit from "@/views/Profile/LegalEntityEdit.vue";

export default defineComponent({
  components: {Card, GvInputText, GvSpinner, GVAutocomplete},
  props: {
    fetchData: {type: Boolean, default: false},
    disableFields: {type: Boolean, default: false},
    showAvatar: {type: Boolean, default: false}
  },
  emits: ['canGoForward'],
  setup(props, {emit}) {
    const router = useRouter();
    const store = useStore(key);
    const toast = useToast();
    const isLoading = ref(false)

    onBeforeMount(() => {
      if (props.fetchData) {
        isLoading.value = true
        Axios.get(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/getLegalEntityData`)
            .then((result: AxiosResponse<LegalEntityType>) => {
              const {data} = result;
              state.ssn = data.ssn;
              state.legalEntityId = data.legalEntityId;
              state.name = data.name;
              state.city = data.city;
              state.signName = data.signName;
              state.legalAddress = data.legalAddress;
              state.zipCode = data.zipCode;
              state.prov = data.prov;
              state.vat = data.vat;
              state.sdi = data.sdi;
              state.legalEmail = data.legalEmail;
              state.administrativeEmail = data.administrativeEmail;
            })
            .catch(() => {
              toast.add({
                severity: 'error',
                summary: "Errore nell'ottenere le informazioni sull'azienda",
                detail: '',
                life: 5000
              });
            }).finally(() => isLoading.value = false);
        emit('canGoForward', true);
      }
    });
    const state = reactive({
      legalEntityId: undefined as unknown as string,
      name: '',
      legalAddress: '',
      city: '',
      zipCode: '',
      prov: '',
      vat: '',
      ssn: '',
      sdi: '',
      legalEmail: '',
      administrativeEmail: '',
      signName: ''
    });
    const rules = computed(() => ({
      name: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      legalAddress: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      city: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      zipCode: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      prov: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      vat: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      signName: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)

      },
      sdi: {
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      legalEmail: {
        email: helpers.withMessage("L'indirizzo email non é valido", email),
        required: helpers.withMessage('Questo campo è obbligatorio', required)
      },
      administrativeEmail: {
        email: helpers.withMessage("L'indirizzo email non é valido", email),
      },
      ssn: {
        minLength: helpers.withMessage("Deve avere almeno un carattere", minLength(0))
      }
    }));
    const v$ = useVuelidate(rules, state);
    const canSubmit = computed((): boolean => v$.value.$invalid);
    watch(canSubmit, (isValid: boolean) => emit('canGoForward', isValid));

    const onLegalAddressSelection = (selectedAddress: IAddressDetails) => {
      state.city = selectedAddress.city
      state.zipCode = selectedAddress.postalCode
      state.prov = selectedAddress.province
    }

    watch(
        () => v$.value,
        newValue => {
          store.dispatch('updateLegalEntity', {
            name: newValue.name.$model,
            legalAddress: newValue.legalAddress.$model,
            city: newValue.city.$model,
            zipCode: newValue.zipCode.$model,
            prov: newValue.prov.$model,
            legalEntityId: state.legalEntityId,
            vat: newValue.vat.$model,
            ssn: newValue.ssn.$model,
            sdi: newValue.sdi.$model,
            legalEmail: newValue.legalEmail.$model,
            administrativeEmail: newValue.administrativeEmail.$model,
            signName: newValue.signName.$model
          });
        }
    );

    const back = () => {
      router.back();
    };

    return {v$, canSubmit, state, props, back, isLoading, onLegalAddressSelection};
  }
});
