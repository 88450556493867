import { Axios } from "@/Axios";
import { IAppointmentGv } from "@/types";
import store from "@/store";
import { add } from "date-fns";
import { DateTime } from "luxon";

const getAppointment = (shopList: any[] = [], start: number, end: number) => {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			await Axios.get(
				`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/getAppointments` +
					"/?minEpochTimeMs=" +
					start.toString() +
					"&maxEpochTimeMs=" +
					end.toString(),
				{
					transformResponse: [
						(data) => {
							const jsonData = JSON.parse(data);
							//Get customer's shop appointments
							return jsonData.map((appointment: IAppointmentGv) => {
								const date = DateTime.fromISO(appointment.event.dateMin);
								const endTime = date
									.plus({
										hours: appointment.event.duration.hh,
										minutes: appointment.event.duration.mm,
									})
									.toISO();

								return {
									id: appointment.id,
									riders: 1,
									vehicle: appointment.vehicleType,
									startTime: appointment.event.dateMin,
									endTime: endTime,
									shop: {
										name: appointment.event.shop.name,
										address: appointment.event.shop.address,
									},
								};
							});
						},
					],
				}
			).then((result) => {
				store.dispatch("setAppointmentsFromOnline", result.data);
			});
		} catch (e) {
			console.error(e);
			reject(Error("Errore nell'ottenere gli appuntamenti"));
		}
	});
};

export { getAppointment };
