import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "p-grid p-flex-column",
  style: {"padding":"1em"}
}
const _hoisted_2 = {
  class: "navigation-buttons p-d-flex p-jc-end",
  style: {"padding":"1em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_UserProfileInfoManagement = _resolveComponent("UserProfileInfoManagement")!
  const _component_Button = _resolveComponent("Button")!
  const _component_GvSpinner = _resolveComponent("GvSpinner")!

  return (_openBlock(), _createBlock(_component_GvSpinner, { "is-loading": $setup.isLoading }, {
    default: _withCtx(() => [
      _createVNode(_component_Toast, { position: "top-center" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UserProfileInfoManagement, {
          "fetch-data": false,
          onCanGoForward: $setup.updateCanSignup,
          "disable-fields": $setup.disableFields
        }, null, 8 /* PROPS */, ["onCanGoForward", "disable-fields"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            class: "gv-button",
            onClick: $setup.performSignup,
            label: "Signup",
            disabled: !$setup.canSignup
          }, null, 8 /* PROPS */, ["onClick", "disabled"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loading"]))
}