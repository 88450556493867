
import {defineComponent, onMounted, ref, watch} from "vue";
import Calendar from "primevue/calendar";
import InputMask from "primevue/inputmask";
import {deviceType} from "@/utils";

export default defineComponent({
  components: {Calendar, InputMask},
  props: {
    minDate: {type: Date},
    errors: {type: Array, required: true},
    maxDate: {type: Date},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    dataLabel: {
      type: String,
      default: "",
    },
    timeLabel: {
      type: String,
      default: "",
    },
    calendarValue: {
      type: [Date, String],
      default: "",
    },
  },
  emits: ["updateCalendarValue"],
  setup(props, {emit}) {
    const date = ref<null | Date>(null);
    const timeRef = ref<string | null>(null);
    const fullDateTime = ref<null | Date>(null);

    // Wait until props.modelValue has value and then Set date value as props.modelValue con component mounted

    onMounted(() => {
      date.value = new Date(props.calendarValue);
      const minutes = new Date(props.calendarValue).getMinutes();
      const hours = new Date(props.calendarValue).getHours();
      timeRef.value = `${getOutputHourString(hours)}:${getOutputMinuteString(
          minutes
      )}`;
    })

    watch(
        () => props.calendarValue,
        (newValue) => {
          date.value = new Date(newValue);
          const minutes = new Date(newValue).getMinutes();
          const hours = new Date(newValue).getHours();
          timeRef.value = `${getOutputHourString(hours)}:${getOutputMinuteString(
              minutes
          )}`;
        },
        {deep: true}
    );

    //Get full date and time
    const getFullDateTime = () => {
      if (date.value && timeRef.value && !isInvalidTime(timeRef.value)) {
        return {valid: true, value: addTimeToDate(date.value, timeRef.value)};
      } else {
        return {valid: false, value: null};
      }
    };

    const isInvalidTime = (time: string) => {
      return time.includes("h") || time.includes("m");
    };

    const addTimeToDate = (date: Date, time: string): Date => {
      let [hours, minutes] = time.split(":");
      const dataCopy = date;
      dataCopy.setHours(Number(hours));
      dataCopy.setMinutes(Number(minutes));
      return dataCopy;
    };

    //Transform hour input number to string with 2 digits
    function getOutputHourString(hourNum: number) {
      return hourNum < 10 ? `0${hourNum}` : hourNum > 23 ? `23` : hourNum;
    }

    //Transform minutes input number to string with 2 digits
    function getOutputMinuteString(minuteNum: number) {
      return minuteNum < 10
          ? `0${minuteNum}`
          : minuteNum > 59
              ? `59`
              : minuteNum;
    }

    //Set max time to 23:59 if user set hour > 23 or minute > 59
    const setMaxTime = (input: { target: { value: string } }) => {
      if (input.target.value !== "") {
        //  split time string into hours and minutes
        const [insertedHours, insertedMinutes] = input.target.value.split(":");
        //convert hours and minutes to numbers
        const hourNum = parseInt(insertedHours);
        const minuteNum = parseInt(insertedMinutes);

        //   set outputHourString with leading 0 if hours is lowe than 10 and 23 if bigger than 23
        const outputHourString = getOutputHourString(hourNum);
        // set outputMinuteString with leading 0 if minutes is lower than 10 and 59 if bigger than 59
        const outputMinuteString = getOutputMinuteString(minuteNum);
        // set time to outputTimeString
        timeRef.value = `${outputHourString}:${outputMinuteString}`;
        updateParentValue();
      }
    };

    function updateParentValue() {
      const {valid, value} = getFullDateTime();
      //  update parent model value
      valid && emit("updateCalendarValue", value);
    }

    return {
      date,
      timeRef,
      deviceType,
      fullDateTime,
      setMaxTime,
      dateToString: getFullDateTime,
      updateParentValue,
    };
  },
});
