
import {computed, defineComponent} from 'vue';

export default defineComponent({
  props: {
    autocomplete: {type: String, default: 'off'},
    type: {type: String, default: 'text'},
    fieldName: {type: String, required: true},
    errors: {type: Array, required: true},
    placeholder: {type: String, default: ''},
    id: {type: String, required: true},
    isDirty: {type: Boolean, default: false},
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    return {
      inputValue
    }
  }
});
