const confirmResetPasswordErrors: Record<string, string> = {
    'auth/expired-action-code': 'Token scaduto',
    'auth/invalid-action-code': 'Token non valido',
    'auth/user-disabled': 'Utente non valido',
    'auth/user-not-found': 'Utente non valido',
    'auth/weak-password': 'La password é troppo debole'
};

const verifyPasswordResetCodeErrors: Record<string, string> = {
    'auth/expired-action-code': 'Token scaduto',
    'auth/invalid-action-code': 'Token non valido',
    'auth/user-disabled': 'Utente non valido',
    'auth/user-not-found': 'Utente non valido'
};

const applyActionCodeErrors: Record<string, string> = {
    'auth/expired-action-code': 'Token scaduto',
    'auth/invalid-action-code': 'Token non valido',
    'auth/user-disabled': 'Utente non valido',
    'auth/user-not-found': 'Utente non valido'
};

export { confirmResetPasswordErrors, verifyPasswordResetCodeErrors, applyActionCodeErrors };
