<template>
    <div class="p-d-flex p-flex-column p-jc-center p-align-center">
        <h2>Got lost?</h2>
        <p>You've come so far and nothing is left here</p>
        <h1>🥺</h1>
    </div>
</template>
<script>
export default {};
</script>
