import axios from 'axios';
import {auth} from '@/firebase';

const Axios = axios.create();
Axios.interceptors.request.use(
    async config => {
        const idToken = localStorage.getItem("token")
        if (idToken) {
            // @ts-ignore
            config.headers['token-id'] = idToken;
        }
        // @ts-ignore
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(response => {
    //Cleaning response from API by deleting service data
    if(response.data !== undefined && response.data.service !== undefined){
        delete response.data.service
    }
    return Promise.resolve(response)
}, error => {
    if (error.response?.status === 500) {
        return Promise.reject({status: error.response.status, data: error.response.data})
    } else if (error.response?.status === 401) {
        auth.currentUser?.getIdToken(true)
    } else {
        return Promise.reject({status: error.response.status, data: error.response.data})
    }
})

export {Axios};
