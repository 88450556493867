
import { defineComponent } from 'vue';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
    components: { ProgressSpinner },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        return { props };
    }
});
