
import {computed, ref} from 'vue';
import {Axios} from '@/Axios';
import {useStore} from 'vuex';
import {key} from '@/store';
import ShopManagement from '../components/ShopManagement.vue';
import Toast from 'primevue/toast';
import {useToast} from 'primevue/usetoast';
import GvSpinner from "@/views/components/GvSpinner.vue";

export default {
  components: {ShopManagement, Toast, GvSpinner},
  props: {
    isInProfileView: {type: Boolean, default: false}
  },
  setup() {
    const isInEditMode = ref(false);
    const store = useStore(key);
    const toast = useToast();
    const isLoading = ref(false)
    const saveChanges = () => {
      isLoading.value = true
      Axios.put(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/updateShops`, store.getters.getPlainShopForUpdate)
          .then(() => {
            toast.add({
              severity: 'success',
              summary: 'Modifiche salvate',
              detail: '',
              life: 5000
            });
            isInEditMode.value = false;
          })
          .catch(err => {
            console.error(err);
            if (err.status === 403) {
              toast.add({
                severity: 'warn',
                summary: 'Impossibile salvare le modifiche',
                detail: 'Uno o piú negozi hanno degli appuntamenti pendenti',
                life: 5000
              });
            } else {
              toast.add({
                severity: 'error',
                summary: 'Errore salvataggio modifiche',
                detail: '',
                life: 5000
              });
            }

          }).finally(() => isLoading.value = false);
    };

    const cancelChanges = () => {
      isInEditMode.value = false;
    };

    const enableEditing = () => {
      isInEditMode.value = true;
    };

    const addShop = () => {
      store.dispatch('addEmptyStore');
      isInEditMode.value = true;
    };

    const areAllStoreValid = computed(() => store.getters.getAreShopsValid)


    return {isInEditMode, enableEditing, cancelChanges, saveChanges, addShop, areAllStoreValid, isLoading};
  }
};
