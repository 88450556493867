
import {defineComponent, onMounted, reactive} from 'vue';
import {auth} from '@/firebase';
import {applyActionCode} from 'firebase/auth';
import {useRouter} from 'vue-router';
import {applyActionCodeErrors} from '@/views/Auth/errors';
import {useToast} from 'primevue/usetoast';
import GvSpinner from '@/views/components/GvSpinner.vue';

export default defineComponent({
  components: {GvSpinner},
  props: {
    actionCode: {type: String, default: ''},
    nextUrl: {type: String, default: ''}
  },
  setup(props) {
    const router = useRouter();
    const toast = useToast();

    const state = reactive({
      verificationInProgress: true,
      isEmailVerified: false
    });

    onMounted(() => {
      applyActionCode(auth, props.actionCode)
          .then(() => (state.isEmailVerified = true))
          .catch(error => {
            toast.add({
              severity: 'error',
              summary: 'Email verification error',
              detail: applyActionCodeErrors[error.code],
              life: 5000
            });
          })
          .finally(() => (state.verificationInProgress = false));
    });

    const goToNextStep = () => props.nextUrl === '' ? router.push('/signin') : window.location.replace(props.nextUrl)


    return {state, goToLogin: goToNextStep};
  }
});
