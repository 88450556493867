
	import { defineComponent, onUpdated, ref, watch } from "vue";
	import AutoComplete from "primevue/autocomplete";
	import {
		AddressLookUp,
		getAddressDetailsService,
	} from "@/service/AddressLookUp";
	import { IAddress, IAddressDetails, IAddressSuggestion } from "@/types";
	import { useToast } from "primevue/usetoast";

	export default defineComponent({
		components: { AutoComplete },
		props: {
			fieldName: { type: String, required: true },
			errors: { type: Array, required: true },
			placeholder: { type: String, default: "" },
			id: { type: String, required: true },
			isDirty: { type: Boolean, default: false },
			modelValue: {
				type: String,
				default: "",
			},
		},
		emits: ["update:modelValue", "onSelection"],

		setup(props, { emit }) {
			const suggestedAddresses = ref<IAddressSuggestion[]>();
			const selectedAddress = ref("");
			const toast = useToast();

			onUpdated(() => {
				if (selectedAddress.value === "" && props.modelValue !== undefined) {
					selectedAddress.value = props.modelValue;
				}
			});

			const onSuggestionSelected = (selectedItem: IAddressSuggestion) => {
				selectedAddress.value = "getting address details...";
				getAddressDetailsService(selectedItem.magicKey)
					.then(({ data }) => {
						debugger;
						selectedAddress.value = selectedItem.text;
						emit("update:modelValue", data);
						emit("onSelection", data);
					})
					.catch((error) => {
						selectedAddress.value = "";
						toast.add({
							severity: "error",
							summary: "Error",
							detail: error.message,
							life: 3000,
						});
					});
			};

			const addressesWithAllData = (data: IAddress) => {
				return (
					data.address !== undefined &&
					!data.address.includes("undefined") &&
					data.shortProvince !== undefined &&
					data.postalCode !== undefined &&
					data.city !== undefined &&
					data.country !== undefined &&
					data.shortProvince.includes("-")
				);
			};

			const formatData = (address: IAddress) => {
				return {
					...address,
					shortProvince:
						address.shortProvince?.split("-")[1] || address.shortProvince,
				};
			};

			//watch suggestedAddresses for changes
			watch(suggestedAddresses, (newValue) => {
				if (newValue?.length === 0) {
					toast.add({
						severity: "warn",
						summary: "Non é stato trovato alcun indirizzo",
						detail: "",
						life: 5000,
					});
				}
			});

			const searchAddress = async (value: any) => {
				const queryValue = value.query.trim();
				if (queryValue.length < 3) {
					suggestedAddresses.value = [];
				} else {
					{
						suggestedAddresses.value = await AddressLookUp(value.query)
							.then((res) => res.data)
							.catch(() => {
								toast.add({
									severity: "error",
									summary: "Impossibile ottenere la lista degli indirizzi",
									detail: "",
									life: 5000,
								});
								return [];
							});
					}
				}
			};

			return {
				searchAddress,
				suggestedAddress: suggestedAddresses,
				selectedAddress,
				onSuggestionSelected,
			};
		},
	});
