
import { ref } from 'vue';
import LegalEntityMenagement from '../components/LegalEntityMenagement.vue';
import { Axios } from '@/Axios';
import { useStore } from 'vuex';
import { key } from '@/store';
import Toast from 'primevue/toast';

import { useToast } from 'primevue/usetoast';

export default {
    components: { LegalEntityMenagement, Toast },
    props: {
        isInProfileView: { type: Boolean, default: false }
    },
    setup() {
        const toast = useToast();
        const isInEditMode = ref(false);
        const store = useStore(key);
        const canSave = ref(false)

        const saveChanges = () => {
            Axios.post(`${process.env.VUE_APP_CUSTOMER_FUNCTIONS_BASE_URL!}/updateLegalEntity`, store.state.signupForm.legalEntity)
                .then(() => {
                    toast.add({
                        severity: 'success',
                        summary: 'Modifiche salvate',
                        detail: '',
                        life: 5000
                    });
                    isInEditMode.value = false;
                })
                .catch(err => {
                    console.error(err);
                    toast.add({
                        severity: 'error',
                        summary: 'Errore salvataggio modifiche',
                        detail: '',
                        life: 5000
                    });
                    Axios.get(process.env.VUE_APP_GET_LEGAL_ENTITY!).then(result => {
                        store.commit('updateLegalEntity', result.data);
                    });
                });
        };

        const cancelChanges = () => {
            isInEditMode.value = false;
        };

        const changeCanSaveStatus = (value: boolean) => {
          canSave.value = !value
        }

        const enableEditing = () => {
            isInEditMode.value = true;
        };

        return { isInEditMode, enableEditing, cancelChanges, saveChanges, canSave, changeCanSaveStatus };
    }
};
