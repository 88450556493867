
import { defineComponent } from 'vue';
import LegalEntityMenagement from '@/views/components/LegalEntityMenagement.vue';

export default defineComponent({
    components: { LegalEntityMenagement },
    emits: ['canGoForward'],
    methods: {
        passEmittedValue(value: boolean) {
            this.$emit('canGoForward', value);
        },
        back() {
            window.history.back();
        }
    }
});
